<template>
    <div class="start-policy-cell flex-row flex-j-start flex-a-center" @click="onClick">
        <lazy-img class="image" :src="item.TitleImgUrl"></lazy-img>
        <div class="flex-col flex-j-around flex-a-start flex-1" style="height: 2.6rem;margin-left: 0.2rem">
            <div class="title">{{item.TitleName}}</div>
            <div class="time">
                <span>{{item.timeText}}</span>
                <span style="margin-left: 0.4rem;">浏览：{{item.ViewTimes}}</span>
            </div>
        </div>
    </div>
</template>

<script>
// start-policy-cell
import LazyImg from "@/components/lazy-img";
export default {
    name: "start-policy-cell",
    components: {LazyImg},
    props: ['item'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        onClick() {
            this.$router.push(`/start-policy-detail?id=${this.item.Id}`)
        }
    }
}
</script>

<style scoped lang="less">
.start-policy-cell {
    background-color: #fff;
    margin-bottom: 0.2rem;
    padding: 0.2rem;
    border-radius: 0.12rem;
    & .image {
        width: 4.0rem;
        border-radius: 0.12rem;
        height: 2.6rem;
        overflow: hidden;
    }
    & .title {
        font-size: 0.36rem;
    }
    & .time {
        font-size: 0.32rem;
        color: #666;
    }
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 0 0.12rem 0.12rem 0 rgba(0, 0, 0, 0.1);
    }
}
</style>
