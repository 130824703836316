<template>
    <div class="start-policy">
        <refresh-list ref="refreshList" @refresh="onPullDownRefresh" @load="onReachBottom">
            <empty-page v-if="!list||list.length===0" msg="暂无相关信息"/>
            <div class="list" style="width: calc(16rem - 0.4rem);margin: 0 auto">
                <start-policy-cell v-for="(item, index) in list" :key="index" :item="item"></start-policy-cell>
            </div>
        </refresh-list>
    </div>
</template>

<script>
// start-policy
import RefreshList from "@/components/refresh-list";
import EmptyPage from "@/components/empty-page";
import StartPolicyCell from "@/components/start-policy-cell";
export default {
    name: "start-policy",
    components: {StartPolicyCell, EmptyPage, RefreshList},
    data() {
        return {
            list: [],
            pageIndex: 1,
            pageSize: 50,
        }
    },
    mounted() {
        this.loadPageList();
    },
    methods: {
        async loadPageList() {
            const params = {
                sort: "BusinessDate",
                sortType: "desc",
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            };
            const res = await $app.httpGet({
                path: `/BaseBusinessInfo/GetBusinessList`,
                params: params,
            });
            this.$refs['refreshList'].stop();
            if (res.ok) {
                let list = this.list || [];
                let newList = res.data || [];

                newList = newList.map(item => {
                    item.timeText = $app.dateFormatChinese(item.BusinessDate);
                    return item;
                });

                if (this.pageIndex === 1) {
                    list = newList;
                } else {
                    list = list.concat(newList);
                    if (newList.length < this.pageSize) {
                        this.$refs['refreshList'].finish();
                    }
                }
                this.list = list;
            }
        },
        onPullDownRefresh() {
            this.pageIndex = 1;
            this.loadPageList();
        },
        onReachBottom() {
            this.pageIndex += 1;
            this.loadPageList();
        }
    }
}
</script>

<style scoped lang="less">
.start-policy {
}
</style>
